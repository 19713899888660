<template>
  <div>
    <svg
      width="178"
      height="178"
      viewBox="0 0 178 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="178"
        height="178"
      >
        <circle cx="89" cy="89" r="89" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <rect
          opacity="0.8"
          x="35"
          y="77.0344"
          width="108"
          height="130.345"
          rx="16"
          fill="url(#paint0_linear)"
        />
        <rect
          x="45.2412"
          y="87.2759"
          width="87.5172"
          height="120.103"
          rx="8"
          fill="white"
        />
        <rect
          x="63.8623"
          y="73.3103"
          width="51.2069"
          height="16.7586"
          rx="8.37931"
          fill="#FF6464"
        />
        <path
          d="M99.2418 73.3103C99.2418 78.4523 95.0734 82.6207 89.9314 82.6207C84.7895 82.6207 80.6211 78.4523 80.6211 73.3103C80.6211 68.1684 84.7895 64 89.9314 64C95.0734 64 99.2418 68.1684 99.2418 73.3103ZM86.452 73.3103C86.452 75.232 88.0098 76.7898 89.9314 76.7898C91.8531 76.7898 93.4109 75.232 93.4109 73.3103C93.4109 71.3887 91.8531 69.8309 89.9314 69.8309C88.0098 69.8309 86.452 71.3887 86.452 73.3103Z"
          fill="#FF6464"
        />
        <mask id="path-6-inside-1" fill="white">
          <rect
            x="57.3447"
            y="104.966"
            width="14.8966"
            height="14.8966"
            rx="2"
          />
        </mask>
        <rect
          x="57.3447"
          y="104.966"
          width="14.8966"
          height="14.8966"
          rx="2"
          stroke="#FA7D7D"
          stroke-width="6"
          mask="url(#path-6-inside-1)"
        />
        <mask id="path-7-inside-2" fill="white">
          <rect
            x="57.3447"
            y="131.966"
            width="14.8966"
            height="14.8966"
            rx="2"
          />
        </mask>
        <rect
          x="57.3447"
          y="131.966"
          width="14.8966"
          height="14.8966"
          rx="2"
          stroke="#FA7D7D"
          stroke-width="6"
          mask="url(#path-7-inside-2)"
        />
        <rect
          x="80.6211"
          y="107.759"
          width="42.8276"
          height="9.31034"
          rx="2"
          fill="#FA7D7D"
        />
        <rect
          x="80.6211"
          y="134.759"
          width="42.8276"
          height="9.31034"
          rx="2"
          fill="#FA7D7D"
        />
        <path
          d="M136 36L138.701 43.2991L146 46L138.701 48.7009L136 56L133.299 48.7009L126 46L133.299 43.2991L136 36Z"
          fill="#E7E7E7"
        />
        <path
          d="M26 69L27.3505 72.6495L31 74L27.3505 75.3505L26 79L24.6495 75.3505L21 74L24.6495 72.6495L26 69Z"
          fill="#E7E7E7"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="95.3529"
          y1="77.0344"
          x2="95.3529"
          y2="215.047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF6666" />
          <stop offset="1" stop-color="#FF4848" />
        </linearGradient>
      </defs>
    </svg>

    <p>目前没有订单哦~</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
svg {
  width: rem(178);
  height: rem(178);
  display: block;
  margin: 0 auto rem(32);
}
p {
  color: #989898;
  font-size: rem(26);
  text-align: center;
}
</style>
