<template>
  <div class="xet_classOrder-tab flex-container">
    <div
      @click="chooese(item, index)"
      v-for="(item, index) in tab"
      :key="index"
      :class="tab_index == index ? 'tab-act' : ''"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tab: {
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      tab_index: 0,
    };
  },

  methods: {
    chooese(item, index) {
      this.tab_index = index;
      this.$emit("chooese", {
        item,
        index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.xet_classOrder-tab {
  width: rem(690);
  margin: rem(20) auto rem(30);
  div {
    width: rem(118);
    height: rem(48);
    line-height: rem(48);
    border-radius: rem(48/2);
    text-align: center;
    color: #666666;
    margin-right: 16px;
  }
}
.tab-act {
  background: #ff4848;
  color: #fff !important;
}
</style>
