<template>
  <div class="xet_classOrder">
    <oTab @chooese="cooeseTab" :tab="tab"></oTab>
    <template v-if="showList && showList.length == 0">
      <oNone></oNone>
    </template>
    <!-- 
        " -->
    <template v-else>
      <div
        class="xet_classOrder_li"
        v-for="(item, index) in showList"
        :key="item.order_id"
        @click="goDetail(item.order_id)"
      >
        <div class="header">
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="22" fill="#222222" />
            <path
              d="M17.7888 15.6971V15.3432C17.7888 15.2371 17.7051 15.1663 17.6214 15.1663H16.1144C15.9135 15.1663 15.7293 14.9894 15.7293 14.7594V10.1769C15.7293 10.0708 15.6456 10 15.5619 10H15.227C15.1265 10 15.0595 10.0885 15.0595 10.1769V14.7594C15.0595 14.9717 14.8921 15.1663 14.6744 15.1663H13.1674C13.0837 15.1486 13 15.2371 13 15.3432V15.6971C13 15.8033 13.0837 15.874 13.1674 15.874H14.6744C14.8753 15.874 15.0595 16.051 15.0595 16.281V29.6745C15.0595 29.7806 15.1433 29.8514 15.227 29.8514H15.5619C15.6623 29.8514 15.7293 29.7629 15.7293 29.6745V16.2633C15.7293 16.051 15.8967 15.8563 16.1144 15.8563H17.6214C17.7219 15.874 17.7888 15.7856 17.7888 15.6971Z"
              fill="white"
            />
            <path
              d="M24.7377 29.126H24.7042C23.7498 29.126 23.3479 28.6306 23.3479 27.4628V15.9094C23.3479 15.4848 23.0298 15.1486 22.6279 15.1486H20.5014C20.3005 15.1486 20.1163 14.9717 20.1163 14.7417V10.1946C20.1163 10.0885 20.0326 10.0177 19.9488 10.0177H19.614C19.5135 10.0177 19.4465 10.1062 19.4465 10.1946V14.7594C19.4465 14.9717 19.2791 15.1663 19.0614 15.1663H18.3581C18.2744 15.1663 18.2074 15.2371 18.2074 15.3255V15.7148C18.2074 15.8033 18.2744 15.874 18.3581 15.874H19.0614C19.2623 15.874 19.4465 16.051 19.4465 16.2633C19.4465 17.4487 19.4465 20.598 19.4465 20.6688C19.2623 27.4982 19.0112 27.8344 17.9395 29.2498L17.9228 29.2675C17.8726 29.3383 17.8726 29.4268 17.9228 29.4975L18.1237 29.7629C18.1572 29.7983 18.1907 29.8337 18.2409 29.8337H18.2577C18.2912 29.8337 18.3414 29.816 18.3749 29.7806L18.3916 29.7629C19.6474 28.3652 19.9321 27.8167 20.1163 20.6865C20.1163 20.6334 20.1163 19.1472 20.133 16.2456C20.133 16.0333 20.3005 15.8563 20.5181 15.8563H22.3265C22.5274 15.8563 22.7116 16.0333 22.7116 16.2633V27.6044C22.7116 28.3475 22.8791 28.896 23.1972 29.2675C23.5321 29.6391 24.0344 29.8337 24.7209 29.8337H24.7544C24.8214 29.8337 24.8884 29.7629 24.8884 29.6921V29.2498C24.8716 29.1791 24.8214 29.126 24.7377 29.126Z"
              fill="white"
            />
            <path
              d="M30.8493 11.2031C30.933 11.2031 31 11.1323 31 11.0439V10.6546C31 10.5662 30.933 10.4954 30.8493 10.4954H24.8884C24.4865 10.4954 24.1684 10.8316 24.1684 11.2562V28.5067C24.1684 28.5952 24.2353 28.666 24.3191 28.666H24.6707C24.7544 28.666 24.8214 28.5952 24.8214 28.5067V11.5924C24.8214 11.38 24.9888 11.1854 25.2065 11.1854H30.8493V11.2031Z"
              fill="white"
            />
            <path
              d="M25.4074 27.5336C25.4744 27.5336 25.4912 27.4805 25.5414 27.4275V27.4098C26.8809 25.0566 26.8809 22.4912 26.8977 18.9526V18.4041C26.8977 18.1918 27.0651 18.0149 27.266 18.0149H28.4047C28.6056 18.0149 28.7898 18.1918 28.7898 18.4218V28.5067C28.7898 28.6129 28.8735 28.6837 28.9572 28.6837H29.2921C29.3926 28.6837 29.4595 28.5952 29.4595 28.5067V18.4218C29.4595 18.2095 29.627 18.0149 29.8447 18.0149H30.7321C30.8326 18.0149 30.8995 17.9264 30.8995 17.8379V17.4841C30.8995 17.3779 30.8158 17.3071 30.7321 17.3071H27.266C27.0651 17.3071 26.8977 17.1302 26.8977 16.9002V14.5117C26.8977 14.3524 26.9981 14.1932 27.1488 14.1401C27.4502 14.034 28.0363 13.804 28.8567 13.4678C29.2251 13.3263 29.627 13.167 30.0623 12.9901C30.146 12.9547 30.1795 12.8309 30.1628 12.7424L30.0791 12.3885C30.0791 12.3531 30.0456 12.3001 30.0121 12.2824C29.9786 12.2647 29.9284 12.2647 29.8949 12.2647L29.7107 12.3531C29.694 12.3531 27.1823 13.3439 26.7302 13.4855C26.4288 13.5916 26.2279 13.8747 26.2279 14.2109V17.8556C26.2279 21.8719 26.2279 24.7912 25.0056 27.0028C24.9721 27.0559 24.9888 27.1444 25.0391 27.2151L25.2567 27.4452C25.307 27.4982 25.3405 27.5336 25.4074 27.5336Z"
              fill="white"
            />
            <path
              d="M30.8326 29.126H25.4577C25.4074 29.126 25.374 29.1437 25.3405 29.1791C25.307 29.2144 25.2902 29.2675 25.2902 29.3029V29.6568C25.2902 29.7629 25.374 29.8337 25.4577 29.8337H30.8326C30.8828 29.8337 30.9163 29.816 30.9498 29.7806C30.9833 29.7452 31 29.6921 31 29.6568V29.3029C31 29.1967 30.9163 29.126 30.8326 29.126Z"
              fill="white"
            />
            <path
              d="M14.6744 32.4345H13.8372C13.72 32.4345 13.6363 32.5407 13.6363 32.6469V34.77C13.6363 34.8938 13.7367 34.9823 13.8372 34.9823C13.9544 34.9823 14.0381 34.8762 14.0381 34.77V34.0623H14.574L15.1767 34.8938C15.2437 34.9823 15.3107 35 15.3609 35C15.4781 35 15.5619 34.8938 15.5619 34.7877C15.5619 34.7346 15.5451 34.6815 15.4949 34.6461L15.0093 33.9915C15.3609 33.8854 15.5619 33.62 15.5619 33.2484C15.5619 33.0361 15.4949 32.8592 15.3609 32.7176C15.2102 32.523 14.9758 32.4345 14.6744 32.4345ZM15.16 33.2307C15.16 33.4961 14.9758 33.6376 14.6577 33.6376H14.0381V32.8415H14.6577C14.9758 32.8415 15.16 32.983 15.16 33.2307Z"
              fill="white"
            />
            <path
              d="M21.9247 32.4168C21.8074 32.4168 21.7237 32.5053 21.7237 32.6292V34.1861L20.5014 32.523C20.4679 32.4699 20.4009 32.4168 20.3172 32.4168H20.267C20.1498 32.4168 20.066 32.523 20.066 32.6292V34.77C20.066 34.8938 20.1665 34.9823 20.267 34.9823C20.3842 34.9823 20.4679 34.8938 20.4679 34.77V33.1776L21.7237 34.8762C21.7572 34.9115 21.8074 34.9823 21.9079 34.9823H21.9247C22.0419 34.9823 22.1256 34.8938 22.1256 34.77V32.6115C22.1256 32.523 22.0251 32.4168 21.9247 32.4168Z"
              fill="white"
            />
            <path
              d="M23.8 32.4168C23.6828 32.4168 23.5991 32.523 23.5991 32.6292V34.77C23.5991 34.8938 23.6995 34.9823 23.8 34.9823C23.9172 34.9823 24.0009 34.8762 24.0009 34.77V32.6469C24.0009 32.523 23.9172 32.4168 23.8 32.4168Z"
              fill="white"
            />
            <path
              d="M27.3498 32.4168C27.2326 32.4168 27.1488 32.5053 27.1488 32.6292V34.1861L25.9265 32.523C25.893 32.4699 25.826 32.4168 25.7423 32.4168H25.6753C25.5581 32.4168 25.4744 32.523 25.4744 32.6292V34.77C25.4744 34.8938 25.5749 34.9823 25.6753 34.9823C25.7926 34.9823 25.8763 34.8938 25.8763 34.77V33.1776L27.1321 34.8762C27.1656 34.9115 27.2158 34.9823 27.3163 34.9823H27.333C27.4502 34.9823 27.534 34.8938 27.534 34.77V32.6115C27.5507 32.523 27.4502 32.4168 27.3498 32.4168Z"
              fill="white"
            />
            <path
              d="M30.6149 34.5754H29.4093V33.903H30.4642C30.5647 33.903 30.6651 33.8146 30.6651 33.6907C30.6651 33.5846 30.5814 33.4784 30.4642 33.4784H29.4093V32.8238H30.5981C30.6986 32.8238 30.7991 32.7353 30.7991 32.6115C30.7991 32.4876 30.7153 32.3992 30.5981 32.3992H29.1916C29.0744 32.3992 28.9907 32.5053 28.9907 32.6115V34.77C28.9907 34.8938 29.0912 34.9823 29.1916 34.9823H30.6149C30.7153 34.9823 30.8158 34.8938 30.8158 34.77C30.8158 34.6638 30.7153 34.5754 30.6149 34.5754Z"
              fill="white"
            />
            <path
              d="M18.6093 34.5754H17.4037V33.903H18.4586C18.5591 33.903 18.6595 33.8146 18.6595 33.6907C18.6595 33.5846 18.5758 33.4784 18.4586 33.4784H17.4037V32.8238H18.5926C18.693 32.8238 18.7935 32.7353 18.7935 32.6115C18.7935 32.4876 18.7098 32.3992 18.5926 32.3992H17.186C17.0688 32.3992 16.9851 32.5053 16.9851 32.6115V34.77C16.9851 34.8938 17.0856 34.9823 17.186 34.9823H18.6093C18.7098 34.9823 18.8102 34.8938 18.8102 34.77C18.8102 34.6638 18.7265 34.5754 18.6093 34.5754Z"
              fill="white"
            />
          </svg>

          <span>十九匠教育</span>
          <b
            :style="{
              color: item.order_state != 0,
            }"
          >
            {{
              item.order_state == 1
                ? getShipState(item.ship_state)
                : getOrderState(item.order_state)
            }}
          </b>
        </div>
        <div class="product">
          <div class="product_li">
            <img :src="item.goods_img" />
            <div class="info">
              <h1>{{ item.title }}</h1>
              <!-- <span>珊瑚橘</span> -->
            </div>
            <div class="price">
              <span>¥{{ regFenToYuan(item.price) }}</span>
              <b>X{{ item.count }}</b>
            </div>
          </div>
        </div>
        <div class="pay">
          <p class="info">
            <span>共1件商品，总金额</span
            ><b>￥{{ regFenToYuan(item.price) }}</b>
          </p>
          <div class="btn">
            <!-- <span class="time">00:09:59</span> -->
            <div
              class="go_pay"
              v-if="item.order_state == 0"
              @click.stop="goXiaoet(item.order_id)"
            >
              去支付
            </div>
            <!-- <div class="go_default">再次购买</div> -->
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { post } from "@get/http";
import oNone from "./nodata.vue";
import oTab from "./tab.vue";
import { Toast } from "vant";
import { getUserInfo } from "@view/me/js/";
export default {
  props: {
    modedata: {},
  },
  components: {
    oNone,
    oTab,
  },
  data() {
    return {
      orderList: [],
      goodsList: [],
      showList: [],
      tabActive: 0,
      tab: [
        { name: "全部", order_state: -1 },
        { name: "待付款", order_state: 0 },
        { name: "待收货", order_state: 1, ship_state: 1 },
        { name: "已收货", order_state: 1, ship_state: 3 },
      ],
    };
  },
  async created() {
    this.getXiaoetData();
  },
  watch: {
    modedata() {
      this.setShowData();
    },
  },
  methods: {
    goDetail(id) {
      // this.$router.push({
      //   name: "Xiaoet_Order_details",
      //   query: {
      //     id: id,
      //   },
      // });
      window.location.href = `https://appg5q9tdm86610.h5.xiaoeknow.com/v1/usercenter/order/order_details?order_id=${id}`;
    },
    async getXiaoetData(jsons = {}) {
      await getUserInfo();
      if (!this.$store.state.user?.xiaoeToken?.user_id) {
        return false;
      }
      Toast.loading({ message: "请求中", forbidClick: true, duration: 0 });
      try {
        let data = await post(
          "/lw.Xiaoet/api",
          {
            data: {
              api_url: "https://api.xiaoe-tech.com/xe.order.list.get/1.0.1",
              method: "POST",
              data: {
                user_id: this.$store.state.user.xiaoeToken.user_id,
                // user_id: "u_6086a97c253ca_hxbSnA7Qn2",
                ...jsons,
              },
            },
          },
          {
            result: true,
            toast: false,
          }
        );

        let list = data.data.list;
        if (list) {
          let arr1 = [],
            arr2 = [];
          for (let index = 0; index < list.length; index++) {
            if (list[index].resource_type == 21) {
              arr1.push(list[index]);
            } else {
              arr2.push(list[index]);
            }
          }
          this.orderList = arr2;
          this.goodsList = arr1;
          this.setShowData();
          Toast.clear();
        }
      } catch (error) {
        Toast.clear();
        console.log(error);
      }
    },
    goXiaoet(id) {
      window.location.href = `https://appg5q9tdm86610.h5.xiaoeknow.com/v1/usercenter/order/order_details?order_id=${id}`;
    },
    cooeseTab({ item, index }) {
      if (index == this.tabActive) {
        return false;
      } else {
        this.tabActive = index;
      }
      if (item.ship_state) {
        this.getXiaoetData({
          order_state: item.order_state,
          ship_state: item.ship_state,
        });
      } else if (item.order_state !== -1) {
        this.getXiaoetData({
          order_state: item.order_state,
        });
      } else {
        this.getXiaoetData();
      }
    },
    regFenToYuan(fen) {
      var num = fen;
      num = fen * 0.01;
      num += "";
      var reg =
        num.indexOf(".") > -1
          ? /(\d{1,3})(?=(?:\d{3})+\.)/g
          : /(\d{1,3})(?=(?:\d{3})+$)/g;
      num = num.replace(reg, "$1");
      num = this.toDecimal2(num);
      return num;
    },
    toDecimal2(x) {
      var f = parseFloat(x);
      if (isNaN(f)) {
        return false;
      }
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 2) {
        s += "0";
      }
      return s;
    },
    setShowData() {
      if (this.modedata == 1) {
        this.showList = this.orderList;
      } else {
        this.showList = this.goodsList;
      }
    },

    getShipState(val) {
      let result = "";
      switch (val) {
        case 0:
          result = "无需发货";
          break;
        case 1:
          result = "待发货";
          break;
        case 2:
          result = "已发货";
          break;
        case 3:
          result = "已收货";
          break;
        case 4:
          result = "部分发货";
          break;
        default:
          result = "未知状态";
      }

      return result;
    },
    getOrderState(val) {
      let result = "";
      switch (val) {
        case 0:
          result = "待支付";
          break;
        case 1:
          result = "已支付";
          break;
        case 2:
          result = "支付失败";
          break;
        case 3:
          result = "已退款";
          break;
        case 6:
          result = "订单超时未支付";
          break;
        case 7:
          result = "已取消";
        case 8:
          result = "退款中";
        case 9:
          result = "退款失败";
        case 10:
          result = "退款成功";
        case 11:
          result = "部分退款";
          break;
        default:
          result = "未知状态";
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.xet_classOrder {
  // margin-top: rem(30);
}
.xet_classOrder_li {
  width: rem(690);
  margin: 0 auto rem(26);
  background: #fff;
  border-radius: rem(16);
  .header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: rem(24) rem(24) 0;
    svg {
      width: rem(44);
      height: rem(44);
    }
    span {
      font-weight: bold;
      font-size: rem(28);
      color: #222222;
    }
    b {
      flex: 1;
      font-size: rem(28);
      color: #ff4848;
      text-align: right;
    }
  }
}

.product {
  .product_li {
    padding: rem(24) rem(16);
    box-sizing: border-box;
    display: flex;
    img {
      width: rem(170);
      height: rem(170);
      border-radius: rem(8);
    }
    .info {
      flex: 1;
      margin: 0 rem(16);
      h1 {
        @include ell2;
        font-size: rem(28);
        color: #222222;
      }
      span {
        color: #989898;
        font-size: rem(24);
      }
    }
    .price {
      color: #989898;
      span {
        display: block;
        font-size: rem(28);
      }
      b {
        display: block;
        font-size: rem(24);
        text-align: right;
      }
    }
  }
}

.pay {
  padding: 0 rem(24) rem(18);
  .info {
    text-align: right;
    margin-bottom: rem(24);
    span {
      font-size: rem(24);
      color: #989898;
    }
    b {
      font-size: rem(28);
      color: #222222;
    }
  }
  .btn {
    color: #ff4848;
    font-size: rem(24);
    display: flex;

    .time {
      flex: 1;
    }
    .go_pay {
      text-align: center;
      line-height: rem(56);
      border-radius: rem(56/2);
      border: rem(2) solid #ff4848;
      width: rem(120);
      height: rem(56);
    }
    .go_default {
      color: #666;
      text-align: center;
      line-height: rem(56);
      border-radius: rem(56/2);
      border: rem(2) solid #666666;
      width: rem(120);
      height: rem(56);
      margin-left: rem(20);
    }
  }
}
</style>
