<template>
  <div class="xiaoet_order">
    <div class="xiaoet_order_bar">
      <svg
        @click="goBack()"
        class="xet_returnPage"
        width="18"
        height="32"
        viewBox="0 0 18 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 2L2 16L16 30"
          stroke="#222222"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="flex-container">
        <div
          @click="tabActive = item.key"
          :class="item.key == tabActive ? 'act' : ''"
          v-for="item in tab"
          :key="item.key"
        >
          {{ item.name }}
          <span v-if="item.key == tabActive"></span>
        </div>
      </div>

      <svg
        v-if="false"
        class="xet_search"
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.5592 17C32.5592 25.558 25.6088 32.5 17.0296 32.5C8.45037 32.5 1.5 25.558 1.5 17C1.5 8.44205 8.45037 1.5 17.0296 1.5C25.6088 1.5 32.5592 8.44205 32.5592 17Z"
          stroke="#C1C1C1"
          stroke-width="3"
        />
        <path
          d="M29.2988 27.7546L35.4789 33.924"
          stroke="#C1C1C1"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    </div>

    <keep-alive>
      <div class="xet_context">
        <oOrder v-if="tabActive == 0"></oOrder>
        <oClassOrder v-if="tabActive == 1" :modedata="1"></oClassOrder>
        <oClassOrder v-if="tabActive == 2" :modedata="2"></oClassOrder>
      </div>
    </keep-alive>

    <van-popup
      v-model="show"
      position="right"
      :style="{ height: '100%', width: '100%' }"
    >
      <oSerach></oSerach>
    </van-popup>
  </div>
</template>

<script>
import oOrder from "../index.vue";
import oClassOrder from "./components/class.vue";
import oSerach from "./components/search.vue";
import { Popup } from "vant";
export default {
  components: {
    oOrder,
    oClassOrder,
    oSerach,
    vanPopup: Popup,
  },
  methods: {
    goBack() {
      try {
        if (window.history.length <= 1) {
          this.$router.push({ name: "HomeIndex" });
          return false;
        } else {
          this.$router.go(-1);
        }
      } catch (error) {
        this.$router.push({ name: "HomeIndex" });
      }
    },
  },
  data() {
    return {
      show: false,
      tabActive: 0,
      tab: [
        {
          name: "十九匠商城",
          key: 0,
        },
        {
          name: "课程学习",
          key: 1,
        },
        {
          name: "直播购物",
          key: 2,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.xiaoet_order_bar {
  display: flex;
  width: rem(750);
  margin: 0 auto;
  align-items: center;
  .xet_returnPage {
    width: rem(14);
    height: rem(28);
    margin: 0 rem(23) 0 rem(30);
  }
  .flex-container {
    height: rem(72);
    align-items: center;
    flex: 1;
    // justify-content: space-between;
    div {
      font-size: rem(30);
      color: #666666;
      position: relative;
      margin: 0 rem(23);
      span {
        position: absolute;
        width: rem(36);
        height: rem(6);
        background: #ff4848;
        border-radius: rem(3);
        bottom: rem(-10);
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    .act {
      color: #ff4848;
    }
  }
  .xet_search {
    width: rem(35.48);
    height: rem(34);
    padding-top: rem(10);
    margin: 0 rem(30) 0 rem(50);
  }
}
</style>
